import axios from 'axios'

class USFactory {

  async bill () {
    try {
      var instance = String(localStorage.getItem('instance'))
      let url = '/' + instance + '/us/snap.js'
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getUSToken (us_order_id:any) {
    try {
      let url = `/us/orders/${us_order_id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

}

export let usFactory = new USFactory()
